import React, {Component} from "react"
import LabelStyles from "./label.module.scss"
import {LabelAppearances} from "./label.interface"

type LabelProps = { 
  appearance: string
};

export default class Label extends React.Component<LabelProps> {
  render() {
    return (
      <div className={`relative ${LabelStyles.label} text-sm inline-block pl-4 pr-4 py-1 mr-2 mb-1 border-grey border bg-white ${LabelStyles[this.props.appearance] ? LabelStyles[this.props.appearance] : ''}`}>
        {this.props.children}
      </div>
    );
  }
}