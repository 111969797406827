import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { MarkdownNode } from "../interface/Markdownnode.interface"
import _ from "lodash"

type SEOProps = {
  isBlog ?: boolean
  markdownnode ?: MarkdownNode
}

interface HeaderData {
  site: {
    siteMetadata: {
      title: string,
      description: string,
      keywords: string,
      author: string,
      siteUrl: string
    }
  }
}

const SEO:FunctionComponent<SEOProps> = ({ isBlog, markdownnode }) => {
  const data: HeaderData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title,
          description,
          keywords,
          author,
          siteUrl
        }
      }
    }
  `)
  // If not blog will default to siteMetadata in gatsbyconfig.js
  return (
    <Helmet>
      	<title lang="en">
		  	{ isBlog && markdownnode.frontmatter.title 
				? `${markdownnode.frontmatter.title} ${markdownnode.frontmatter.subtitle ? ' | ' + markdownnode.frontmatter.subtitle : ''}`
				: data.site.siteMetadata.title
			}
		</title>

		<meta 
			name="description" 
			content= { 
				isBlog && markdownnode.frontmatter.summary 
					? markdownnode.frontmatter.summary 
					: data.site.siteMetadata.description }/>

		<meta 
			name="keywords" 
			content={
				isBlog && markdownnode
					? _.concat(markdownnode.frontmatter.tagsUI, markdownnode.frontmatter.tagsDev).join() // String of combined tags
					: data.site.siteMetadata.keywords
				} />
		<meta name="author" content={data.site.siteMetadata.author} />
		<meta name="robots" content="index, follow" />
		<meta property="og:type" content={ isBlog ? "blog" : "portfolio"} />
    </Helmet>
  );
}

export default SEO;

