import React, {Component} from "react"
import style from "./button.module.scss"

type ButtonProps = { 
  text: string,
  url?: string,
  target?: string,
  appearance?: string
  disabled?: boolean
};

export default class Button extends React.Component<ButtonProps> {
  render() {
    return (
      <a 
        className={`
            whitespace-no-wrap
            inline-block
            border-primary 
            cursor-pointer
            p-4
            font-semibold
            transition duration-100
            ${this.props.disabled ? style.disabled : ''}
            ${this.props.appearance ? style[this.props.appearance] : ''}
        `}
        href={this.props.url}
        target={this.props.target}>
        {this.props.text}
      </a>
    );
  }
}